// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thank-you-container {
    text-align: center;
    padding: 50px 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.thank-you-container h1 {
    font-size: 2.5em;
    color: #FFD700;
}

.thank-you-container p {
    font-size: 1.2em;
    margin: 20px 0;
}

.thank-you-links button {
    display: inline-block;
    margin: 10px;
    /* padding: 10px 20px;
    background-color: #FFD700;
    color: 000000;
    text-decoration: none;
    border-radius: 4px; */
}

.social-media-links a {
    margin: 10px;
    color: #3b5998; /* Facebookのブルー */
    text-decoration: none;
}

.social-media-links a:hover {
    text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/css/ThankYou.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ;;;;yBAIqB;AACzB;;AAEA;IACI,YAAY;IACZ,cAAc,EAAE,iBAAiB;IACjC,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".thank-you-container {\n    text-align: center;\n    padding: 50px 20px;\n    background-color: #f7f7f7;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.thank-you-container h1 {\n    font-size: 2.5em;\n    color: #FFD700;\n}\n\n.thank-you-container p {\n    font-size: 1.2em;\n    margin: 20px 0;\n}\n\n.thank-you-links button {\n    display: inline-block;\n    margin: 10px;\n    /* padding: 10px 20px;\n    background-color: #FFD700;\n    color: 000000;\n    text-decoration: none;\n    border-radius: 4px; */\n}\n\n.social-media-links a {\n    margin: 10px;\n    color: #3b5998; /* Facebookのブルー */\n    text-decoration: none;\n}\n\n.social-media-links a:hover {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
