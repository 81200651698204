// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContactFrom from './components/ContactFrom';
import ContactList from './components/ContactList';
import ThankYou from './components/ThankYou';
import Terms from './components/TermsOfService';
import URLShortener from './components/URLShortener';
import QRCodeGenerator from './components/QRCodeGenerator';
import PasswordGenerator from './components/PasswordGenerator';
import WiFiQRCodeGenerator from './components/WiFiQRCodeGenerator';
import ColorCodeConverter from './components/ColorCodeConverter';
import PersonalInfoGenerator from './components/PersonalInfoGenerator';
import DateConverter from './components/DateConverter';
import ExcelToCsvConverter from './components/ExcelToCsvConverter';
import FaviconGenerator from './components/FaviconGenerator';
import Home from './components/Home';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import './components/css/Footer.css'

export const SITE_NAME = "GenieApp";

function App() {
  const [sidebar, setSidebar] = useState(false);

  return (
    <Router>
      <div className="App">
        <Header showSidebar={() => setSidebar(!sidebar)} />
        <Sidebar sidebar={sidebar} setSidebar={setSidebar} />
        <div className="content">
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<ContactFrom />} />
              <Route path="/admin/contacts" element={<ContactList />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/qr-generator" element={<QRCodeGenerator />} />
              <Route path="/wifi-qr-generator" element={<WiFiQRCodeGenerator />} />
              <Route path="/password-generator" element={<PasswordGenerator />} />
              <Route path="/color-code-converter" element={<ColorCodeConverter />} />
              <Route path="/personal-info-generator" element={<PersonalInfoGenerator />} />
              <Route path="/date-converter" element={<DateConverter />} />
              <Route path="/excel-to-csv" element={<ExcelToCsvConverter />} />
              <Route path="/favicon-generator" element={<FaviconGenerator />} />
              <Route path="/url-shorten" element={<URLShortener />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

