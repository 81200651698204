import React, { useState } from "react";
import axios from "axios";
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../components/css/ContactForm.css';

function ContactForm() {
    const { t } = useTranslation();
    const PAGE_NAME = t('contact');
    const PAGE_DESCRIPTION = t('contactDescription');
    const META_DESCRIPTION = t('contactMetaDescription');
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            // バックエンドにデータを送信する
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/contact`, formData);
            navigate("/thank-you"); // 送信完了後に/thank-youページへ遷移
        } catch (error) {
            console.error("Error submitting the form:", error);
            alert("There was an error submitting the form. Please try again.");
        }
    };

    return (
        <form className="contact-form" onSubmit={handleSubmit}>
            <Helmet>
                <title>{PAGE_NAME} - {t('siteName')}</title>
                <meta name="description" content={META_DESCRIPTION.replace(/<br\/>/g, ' ')} />
            </Helmet>
            <h1>{PAGE_NAME}</h1>
            <p>
                <Trans i18nKey={PAGE_DESCRIPTION} components={{ br: <br /> }} />
            </p>
            <div className="form-group">
                <label htmlFor="name">{t('name')}</label>
                <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="email">{t('email')}</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="message">{t('message')} <span className="required">*</span></label>
                <textarea
                    name="message"
                    id="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                />
            </div>
            <button type="submit">{t('submit')}</button>
        </form>
    );
}

export default ContactForm;
