// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../App.css';

const Footer = () => {
    const { t } = useTranslation()

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="copyright">
                    <p>© 2024 {t('GenieApp')}. {t('All rights reserved.')}</p>
                </div>
                <div className="footer-links">
                    <Link to="/">Home</Link>
                    <Link to="/terms">Terms</Link>
                    <Link to="/contact">Contact</Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
