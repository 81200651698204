// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-form {
    width: 70%;
    /* max-width: 500px; */
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    width: 60%;
    margin: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group .required {
    color: red;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.form-group textarea {
    height: 100px;
    resize: vertical;
}`, "",{"version":3,"sources":["webpack://./src/components/css/ContactForm.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,sBAAsB;IACtB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;;IAEI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".contact-form {\n    width: 70%;\n    /* max-width: 500px; */\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.form-group {\n    width: 60%;\n    margin: 15px;\n}\n\n.form-group label {\n    display: block;\n    margin-bottom: 5px;\n    font-weight: bold;\n}\n\n.form-group .required {\n    color: red;\n}\n\n.form-group input,\n.form-group textarea {\n    width: 100%;\n    padding: 10px;\n    margin: auto;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    font-size: 16px;\n}\n\n.form-group textarea {\n    height: 100px;\n    resize: vertical;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
