import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import '../App.css';
import './css/PersonalInfo.css'

function PersonalInfoGenerator() {
    const { t } = useTranslation();
    const PAGE_NAME = t('personalInfoGenerator');
    const PAGE_DESCRIPTION = t('personalInfoGeneratorDescription');

    const [settings, setSettings] = useState({
        name: { selected: true, formats: ['kanji'], separator: 'space' },
        age: { selected: false, min: 18, max: 65 },
        birthdate: { selected: false, format: 'YYYY/MM/DD' },
        gender: { selected: false, format: 'MFO' },
        bloodType: { selected: false },
        email: { selected: false },
        phoneNumber: { selected: false },
        mobileNumber: { selected: false, hyphen: false },
        postalCode: { selected: false, hyphen: false },
        address: { selected: false },
        companyName: { selected: false },
        creditCard: { selected: false, types: [] },
        myNumber: { selected: false },
        generateCount: 1
    });

    const [generatedInfo, setGeneratedInfo] = useState([]);

    const handleSettingChange = (field, value) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            [field]: {
                ...prevSettings[field],
                selected: value
            }
        }));
    };

    const handleSubSettingChange = (field, subField, value) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            [field]: {
                ...prevSettings[field],
                [subField]: value
            }
        }));
    };

    const handleNameFormatChange = (format) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            name: {
                ...prevSettings.name,
                formats: prevSettings.name.formats.includes(format)
                    ? prevSettings.name.formats.filter(f => f !== format)
                    : [...prevSettings.name.formats, format]
            }
        }));
    };

    const handleCreditCardTypeChange = (type) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            creditCard: {
                ...prevSettings.creditCard,
                types: prevSettings.creditCard.types.includes(type)
                    ? prevSettings.creditCard.types.filter(t => t !== type)
                    : [...prevSettings.creditCard.types, type]
            }
        }));
    };

    const generateRandomName = () => {
        const kanjiFamilyNames = ["山田", "鈴木", "田中", "佐藤", "高橋", "伊藤", "渡辺", "山本", "中村", "小林", "加藤", "吉田", "山口", "松本", "井上", "木村", "林", "清水", "斎藤", "山崎", "森", "池田", "橋本", "阿部", "石川", "中島", "前田", "藤田", "後藤", "岡田", "長谷川", "村上", "石井", "斉藤", "坂本", "中川", "原", "金子", "小松", "福田"];
        const kanjiGivenNames = ["太郎", "花子", "次郎", "恵子", "一郎", "二郎", "三郎", "四郎", "五郎", "六郎", "七郎", "八郎", "九郎", "十郎", "正一", "正二", "正三", "正四", "正五", "正六", "正七", "正八", "正九", "正十", "健一", "健二", "健三", "健四", "健五", "健六", "健七", "健八", "健九", "健十", "光一", "光二", "光三", "光四", "光五", "光六", "光七", "光八", "光九", "光十"];
        const hiraganaFamilyNames = ["やまだ", "すずき", "たなか", "さとう", "たかはし", "いとう", "わたなべ", "やまもと", "なかむら", "こばやし", "かとう", "よしだ", "やまぐち", "まつもと", "いのうえ", "きむら", "はやし", "しみず", "さいとう", "やまざき", "もり", "いけだ", "はしもと", "あべ", "いしかわ", "なかじま", "まえだ", "ふじた", "ごとう", "おかだ", "はせがわ", "むらかみ", "いしい", "さいとう", "さかもと", "なかがわ", "はら", "かねこ", "こまつ", "ふくだ"];
        const hiraganaGivenNames = ["たろう", "はなこ", "じろう", "けいこ", "いちろう", "じろう", "さぶろう", "しろう", "ごろう", "ろくろう", "しちろう", "はちろう", "くろう", "じゅうろう", "しょういち", "しょうに", "しょうさん", "しょうし", "しょうご", "しょうろく", "しょうしち", "しょうはち", "しょうく", "しょうじゅう", "けんいち", "けんに", "けんさん", "けんし", "けんご", "けんろく", "けんしち", "けんはち", "けんく", "けんじゅう", "こういち", "こうに", "こうさん", "こうし", "こうご", "こうろく", "こうしち", "こうはち", "こうく", "こうじゅう"];
        const katakanaFamilyNames = ["ヤマダ", "スズキ", "タナカ", "サトウ", "タカハシ", "イトウ", "ワタナベ", "ヤマモト", "ナカムラ", "コバヤシ", "カトウ", "ヨシダ", "ヤマグチ", "マツモト", "イノウエ", "キムラ", "ハヤシ", "シミズ", "サイトウ", "ヤマザキ", "モリ", "イケダ", "ハシモト", "アベ", "イシカワ", "ナカジマ", "マエダ", "フジタ", "ゴトウ", "オカダ", "ハセガワ", "ムラカミ", "イシイ", "サイトウ", "サカモト", "ナカガワ", "ハラ", "カネコ", "コマツ", "フクダ"];
        const katakanaGivenNames = ["タロウ", "ハナコ", "ジロウ", "ケイコ", "イチロウ", "ジロウ", "サブロウ", "シロウ", "ゴロウ", "ロクロウ", "シチロウ", "ハチロウ", "クロウ", "ジュウロウ", "ショウイチ", "ショウニ", "ショウサン", "ショウシ", "ショウゴ", "ショウロク", "ショウシチ", "ショウハチ", "ショウク", "ショウジュウ", "ケンイチ", "ケンニ", "ケンサン", "ケンシ", "ケンゴ", "ケンロク", "ケンシチ", "ケンハチ", "ケンク", "ケンジュウ", "コウイチ", "コウニ", "コウサン", "コウシ", "コウゴ", "コウロク", "コウシチ", "コウハチ", "コウク", "コウジュウ"];
        const romajiFamilyNames = ["Yamada", "Suzuki", "Tanaka", "Sato", "Takahashi", "Ito", "Watanabe", "Yamamoto", "Nakamura", "Kobayashi", "Kato", "Yoshida", "Yamaguchi", "Matsumoto", "Inoue", "Kimura", "Hayashi", "Shimizu", "Saito", "Yamazaki", "Mori", "Ikeda", "Hashimoto", "Abe", "Ishikawa", "Nakajima", "Maeda", "Fujita", "Goto", "Okada", "Hasegawa", "Murakami", "Ishii", "Saito", "Sakamoto", "Nakagawa", "Hara", "Kaneko", "Komatsu", "Fukuda"];
        const romajiGivenNames = ["Taro", "Hanako", "Jiro", "Keiko", "Ichiro", "Jiro", "Saburo", "Shiro", "Goro", "Rokuro", "Shichiro", "Hachiro", "Kuro", "Juro", "Shoichi", "Shoni", "Shosan", "Shoshi", "Shogo", "Shoroku", "Shoshichi", "Shohachi", "Shoku", "Shoju", "Kenichi", "Keni", "Kensan", "Kenshi", "Kengo", "Kenroku", "Kenshichi", "Kehachi", "Koku", "Kenju", "Koichi", "Koni", "Kosan", "Koshi", "Kogo", "Koroku", "Koshichi", "Kohachi", "Koku", "Koju"];
        
        const randomIndex = Math.floor(Math.random() * kanjiFamilyNames.length);

        return {
            kanjiFamily: kanjiFamilyNames[randomIndex],
            kanjiGiven: kanjiGivenNames[randomIndex],
            hiraganaFamily: hiraganaFamilyNames[randomIndex],
            hiraganaGiven: hiraganaGivenNames[randomIndex],
            katakanaFamily: katakanaFamilyNames[randomIndex],
            katakanaGiven: katakanaGivenNames[randomIndex],
            romajiFamily: romajiFamilyNames[randomIndex],
            romajiGiven: romajiGivenNames[randomIndex]
        };
    };

    const generateRandomAge = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const generateRandomBirthdate = (age, format) => {
        const today = new Date();
        const birthYear = today.getFullYear() - age;
        const birthMonth = ("0" + (Math.floor(Math.random() * 12) + 1)).slice(-2);
        const birthDay = ("0" + (Math.floor(Math.random() * 28) + 1)).slice(-2);

        switch (format) {
            case 'YYYY-MM-DD':
                return `${birthYear}-${birthMonth}-${birthDay}`;
            case 'YYYY/MM/DD':
                return `${birthYear}/${birthMonth}/${birthDay}`;
            case 'YYYY年MM月DD日':
                return `${birthYear}年${birthMonth}月${birthDay}日`;
            case 'YYYYMMDD':
                return `${birthYear}${birthMonth}${birthDay}`;
            default:
                return `${birthYear}-${birthMonth}-${birthDay}`;
        }
    };

    const generateRandomGender = (format) => {
        const genderFormats = {
            'MFO': ['男', '女', 'その他'],
            'male-female-other': ['男性', '女性', 'その他'],
            'MFO-tr': ['M', 'F', 'U'],
            'male-female-other-tr': ['Male', 'Female', 'Other']
        };
        const genders = genderFormats[format] || genderFormats['MFO'];
        return genders[Math.floor(Math.random() * genders.length)];
    };

    const generateRandomBloodType = () => {
        const bloodTypes = ['A', 'B', 'O', 'AB'];
        return bloodTypes[Math.floor(Math.random() * bloodTypes.length)];
    };

    const generateRandomEmail = (familyName, givenName) => {
        const domains = ["example.com", "test.com"];
        const domain = domains[Math.floor(Math.random() * domains.length)];
        if (!familyName || !givenName) {
            return `user${Math.floor(Math.random() * 10000)}@${domain}`;
        }
        const localPart = `${familyName.toLowerCase()}.${givenName.toLowerCase()}`;
        return `${localPart}@${domain}`;
    };

    const generateRandomPhoneNumber = () => {
        const areaCodes = ['03', '06', '011', '052', '075'];
        const areaCode = areaCodes[Math.floor(Math.random() * areaCodes.length)];
        const number = `${areaCode}-${("0000" + Math.floor(Math.random() * 10000)).slice(-4)}-${("0000" + Math.floor(Math.random() * 10000)).slice(-4)}`;
        return number;
    };

    const generateRandomMobileNumber = (hyphen) => {
        const prefixes = ['090', '080', '070', '050'];
        const prefix = prefixes[Math.floor(Math.random() * prefixes.length)];
        const number = `${("0000" + Math.floor(Math.random() * 10000)).slice(-4)}-${("0000" + Math.floor(Math.random() * 10000)).slice(-4)}`;
        return hyphen ? `${prefix}-${number}` : `${prefix}${number.replace(/-/g, '')}`;
    };

    const generateRandomPostalCode = (hyphen) => {
        const postalCode = `${("000" + Math.floor(Math.random() * 1000)).slice(-3)}-${("0000" + Math.floor(Math.random() * 10000)).slice(-4)}`;
        return hyphen ? postalCode : postalCode.replace(/-/g, '');
    };

    const generateRandomAddress = () => {
        const prefectures = ['北海道', '東京都', '大阪府', '愛知県', '福岡県'];
        const cities = ['札幌市', '新宿区', '大阪市', '名古屋市', '福岡市'];
        const town = ['中央区', '北区', '西区', '南区', '東区'];
        const street = ['1丁目1-1', '2丁目2-2', '3丁目3-3', '4丁目4-4', '5丁目5-5'];

        const prefecture = prefectures[Math.floor(Math.random() * prefectures.length)];
        const city = cities[Math.floor(Math.random() * cities.length)];
        const townStreet = town[Math.floor(Math.random() * town.length)] + street[Math.floor(Math.random() * street.length)];

        return `${prefecture}${city}${townStreet}`;
    };

    const generateRandomCompanyName = () => {
        const companyNames = ['株式会社山田', '鈴木商事', '田中工業', '佐藤不動産', '山本電機'];
        return companyNames[Math.floor(Math.random() * companyNames.length)];
    };

    const generateRandomCreditCard = (types) => {
        const cardNumbers = {
            'Visa': '4111111111111111',
            'MasterCard': '5500000000000004',
            'JCB': '3530111333300000',
            'AmericanExpress': '371449635398431',
            'DinersClub': '30569309025904',
            'DiscoverCard': '6011000990139424',
            'BCGlobal': '5641821111161165'
        };
        const randomType = types[Math.floor(Math.random() * types.length)];
        return { type: randomType, number: cardNumbers[randomType], expiry: '12/25' };
    };

    const generateRandomMyNumber = () => {
        return `${("0000" + Math.floor(Math.random() * 10000)).slice(-4)}-${("0000" + Math.floor(Math.random() * 10000)).slice(-4)}-${("000" + Math.floor(Math.random() * 1000)).slice(-3)}`;
    };

    const generatePersonalInfo = () => {
        const generatedData = [];

        for (let i = 0; i < settings.generateCount; i++) {
            const data = {};

            if (settings.name.selected) {
                const names = generateRandomName();
                const separator = settings.name.separator;

                if (settings.name.formats.includes('kanji')) {
                    if (separator === 'separate') {
                        data.kanjiFamily = names.kanjiFamily;
                        data.kanjiGiven = names.kanjiGiven;
                    } else {
                        data.kanji = names.kanjiFamily + (separator === 'space' ? ' ' : separator === 'fullSpace' ? '　' : '') + names.kanjiGiven;
                    }
                }

                if (settings.name.formats.includes('hiragana')) {
                    if (separator === 'separate') {
                        data.hiraganaFamily = names.hiraganaFamily;
                        data.hiraganaGiven = names.hiraganaGiven;
                    } else {
                        data.hiragana = names.hiraganaFamily + (separator === 'space' ? ' ' : separator === 'fullSpace' ? '　' : '') + names.hiraganaGiven;
                    }
                }

                if (settings.name.formats.includes('katakana')) {
                    if (separator === 'separate') {
                        data.katakanaFamily = names.katakanaFamily;
                        data.katakanaGiven = names.katakanaGiven;
                    } else {
                        data.katakana = names.katakanaFamily + (separator === 'space' ? ' ' : separator === 'fullSpace' ? '　' : '') + names.katakanaGiven;
                    }
                }

                if (settings.name.formats.includes('romaji')) {
                    if (separator === 'separate') {
                        data.romajiFamily = names.romajiFamily;
                        data.romajiGiven = names.romajiGiven;
                    } else {
                        data.romaji = names.romajiFamily + ' ' + names.romajiGiven;
                    }
                }
            }
            if (settings.age.selected) {
                data.age = generateRandomAge(settings.age.min, settings.age.max);
            }

            if (settings.birthdate.selected) {
                const birthdate = generateRandomBirthdate(data.age, settings.birthdate.format);
                data.birthdate = birthdate;
            }

            if (settings.gender.selected) {
                data.gender = generateRandomGender(settings.gender.format);
            }

            if (settings.bloodType.selected) {
                data.bloodType = generateRandomBloodType();
            }

            if (settings.email.selected && settings.name.selected) {
                const familyName = data.kanjiFamily || data.romajiFamily;
                const givenName = data.kanjiGiven || data.romajiGiven;
                data.email = generateRandomEmail(familyName, givenName);
            } else if (settings.email.selected) {
                data.email = generateRandomEmail();
            }

            if (settings.phoneNumber.selected) {
                data.phoneNumber = generateRandomPhoneNumber();
            }

            if (settings.mobileNumber.selected) {
                data.mobileNumber = generateRandomMobileNumber(settings.mobileNumber.hyphen);
            }

            if (settings.postalCode.selected) {
                data.postalCode = generateRandomPostalCode(settings.postalCode.hyphen);
            }

            if (settings.address.selected) {
                data.address = generateRandomAddress();
            }

            if (settings.companyName.selected) {
                data.companyName = generateRandomCompanyName();
            }

            if (settings.creditCard.selected && settings.creditCard.types.length > 0) {
                const card = generateRandomCreditCard(settings.creditCard.types);
                // data.creditCardType = card.type;
                data.creditCardNumber = card.number;
                data.creditCardExpiry = card.expiry;
            }

            if (settings.myNumber.selected) {
                data.myNumber = generateRandomMyNumber();
            }

            generatedData.push(data);
        }
        
        setGeneratedInfo(generatedData);
    };

    const downloadCSV = () => {
        if (generatedInfo.length > 0) {
            const keys = Object.keys(generatedInfo[0]);
            const csvContent = [
                keys.join(","), 
                ...generatedInfo.map(info => keys.map(key => info[key]).join(","))
            ].join("\n");

            const blob = new Blob([`\uFEFF${csvContent}`], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "generated_info.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <div>
            <Helmet>
                <title>{PAGE_NAME} - {t('siteName')}</title>
                <meta name="description" content={PAGE_DESCRIPTION.replace(/<br\/>/g, ' ')} />
            </Helmet>
            <h1>{PAGE_NAME}</h1>
            <section className="app-description">
                <h3>{t('description')}</h3>
                <p>
                    <Trans i18nKey={PAGE_DESCRIPTION} components={{ br: <br /> }} />
                </p>
            </section>
            <div className='generate-count'>
                <label>{t('generateCount')}:
                <input
                    type="number"
                    value={settings.generateCount}
                    onChange={(e) => setSettings(prevSettings => ({
                    ...prevSettings,
                    generateCount: parseInt(e.target.value, 10)
                    }))}
                    min="1"
                    max="500"
                />
                </label>
            </div>
            <div className="personal-info-settings">
                {/* <h2>{t('selectInfo')}</h2> */}
                {/* 氏名 */}
                <div>
                    <label>
                    <input
                        type="checkbox"
                        checked={settings.name.selected}
                        onChange={(e) => handleSettingChange('name', e.target.checked)}
                    />
                    {t('name')}
                    </label>
                    {settings.name.selected && (
                    <div className="sub-settings">
                        <label>
                            <input
                            type="checkbox"
                            checked={settings.name.formats.includes('kanji')}
                            onChange={() => handleNameFormatChange('kanji')}
                            />
                            {t('kanji')}
                        </label>
                        <label>
                            <input
                            type="checkbox"
                            checked={settings.name.formats.includes('hiragana')}
                            onChange={() => handleNameFormatChange('hiragana')}
                            />
                            {t('hiragana')}
                        </label>
                        <label>
                            <input
                            type="checkbox"
                            checked={settings.name.formats.includes('katakana')}
                            onChange={() => handleNameFormatChange('katakana')}
                            />
                            {t('katakana')}
                        </label>
                        <label>
                            <input
                            type="checkbox"
                            checked={settings.name.formats.includes('romaji')}
                            onChange={() => handleNameFormatChange('romaji')}
                            />
                            {t('romaji')}
                        </label>
                        <div>
                        {t('separator')}:
                        <select
                            value={settings.name.separator}
                            onChange={(e) => handleSubSettingChange('name', 'separator', e.target.value)}
                        >
                            <option value="space">{t('halfSpace')}</option>
                            <option value="fullSpace">{t('fullSpace')}</option>
                            <option value="none">{t('noSeparator')}</option>
                            <option value="separate">{t('separateNames')}</option>
                        </select>
                        </div>
                    </div>
                    )}
                </div>
                {/* 年齢 */}
                <div>
                    <label>
                    <input
                        type="checkbox"
                        checked={settings.age.selected}
                        onChange={(e) => handleSettingChange('age', e.target.checked)}
                    />
                    {t('age')}
                    </label>
                    {settings.age.selected && (
                    <div className="sub-settings">
                        <label>{t('minAge')}:
                        <input
                            type="number"
                            value={settings.age.min}
                            onChange={(e) => handleSubSettingChange('age', 'min', e.target.value)}
                            min="0"
                            max="150"
                        />
                        </label>
                        <label>{t('maxAge')}:
                        <input
                            type="number"
                            value={settings.age.max}
                            onChange={(e) => handleSubSettingChange('age', 'max', e.target.value)}
                            min="0"
                            max="150"
                        />
                        </label>
                    </div>
                    )}
                </div>
                {/* 生年月日 */}
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={settings.birthdate.selected}
                            onChange={(e) => handleSettingChange('birthdate', e.target.checked)}
                        />
                        {t('birthdate')}
                    </label>
                    {settings.birthdate.selected && (
                        <div className="sub-settings">
                            <label>
                                {t('format')}:
                                <select
                                    value={settings.birthdate.format}
                                    onChange={(e) => handleSubSettingChange('birthdate', 'format', e.target.value)}
                                >
                                    <option value="YYYY/MM/DD">{t('formatYYYYMMDD')}</option>
                                    <option value="YYYY-MM-DD">{t('formatYYYYMMDDHyphen')}</option>
                                    <option value="YYYY年MM月DD日">{t('formatYYYYMMDDKanji')}</option>
                                    <option value="YYYYMMDD">{t('formatYYYYMMDDNoSeparator')}</option>
                                </select>
                            </label>
                        </div>
                    )}
                </div>
                {/* 性別 */}
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={settings.gender.selected}
                            onChange={(e) => handleSettingChange('gender', e.target.checked)}
                        />
                        {t('gender')}
                    </label>
                    {settings.gender.selected && (
                        <div className="sub-settings">
                            <label>
                                {t('format')}:
                                <select
                                    value={settings.gender.format}
                                    onChange={(e) => handleSubSettingChange('gender', 'format', e.target.value)}
                                >
                                    <option value="MFO">{t('MFO')}</option>
                                    <option value="male-female-other">{t('maleFemaleOther')}</option>
                                    <option value="MFO-tr">{t('MFO-tr')}</option>
                                    <option value="male-female-other-tr">{t('maleFemaleOther-tr')}</option>
                                </select>
                            </label>
                        </div>
                    )}
                </div>
                {/* 血液型 */}
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={settings.bloodType.selected}
                            onChange={(e) => handleSettingChange('bloodType', e.target.checked)}
                        />
                        {t('bloodType')}
                    </label>
                </div>
                {/* メールアドレス */}
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={settings.email.selected}
                            onChange={(e) => handleSettingChange('email', e.target.checked)}
                        />
                        {t('email')}
                    </label>
                </div>
                {/* 電話番号 */}
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={settings.phoneNumber.selected}
                            onChange={(e) => handleSettingChange('phoneNumber', e.target.checked)}
                        />
                        {t('phoneNumber')}
                    </label>
                </div>
                {/* 携帯電話番号 */}
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={settings.mobileNumber.selected}
                            onChange={(e) => handleSettingChange('mobileNumber', e.target.checked)}
                        />
                        {t('mobileNumber')}
                    </label>
                    {settings.mobileNumber.selected && (
                        <di className="sub-settings">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={settings.mobileNumber.hyphen}
                                    onChange={(e) => handleSubSettingChange('mobileNumber', 'hyphen', e.target.checked)}
                                />
                                {t('includeHyphen')}
                            </label>
                        </di>
                    )}
                </div>
                {/* 郵便番号 */}
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={settings.postalCode.selected}
                            onChange={(e) => handleSettingChange('postalCode', e.target.checked)}
                        />
                        {t('postalCode')}
                    </label>
                    {settings.postalCode.selected && (
                        <div className="sub-settings">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={settings.postalCode.hyphen}
                                    onChange={(e) => handleSubSettingChange('postalCode', 'hyphen', e.target.checked)}
                                />
                                {t('includeHyphen')}
                            </label>
                        </div>
                    )}
                </div>
                {/* 住所 */}
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={settings.address.selected}
                            onChange={(e) => handleSettingChange('address', e.target.checked)}
                        />
                        {t('address')}
                    </label>
                </div>
                {/* 会社名 */}
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={settings.companyName.selected}
                            onChange={(e) => handleSettingChange('companyName', e.target.checked)}
                        />
                        {t('companyName')}
                    </label>
                </div>
                {/* クレジットカード */}
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={settings.creditCard.selected}
                            onChange={(e) => handleSettingChange('creditCard', e.target.checked)}
                        />
                        {t('creditCard')}
                    </label>
                    {settings.creditCard.selected && (
                        <div className="sub-settings">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={settings.creditCard.types.includes('Visa')}
                                    onChange={() => handleCreditCardTypeChange('Visa')}
                                />
                                Visa
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={settings.creditCard.types.includes('MasterCard')}
                                    onChange={() => handleCreditCardTypeChange('MasterCard')}
                                />
                                MasterCard
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={settings.creditCard.types.includes('JCB')}
                                    onChange={() => handleCreditCardTypeChange('JCB')}
                                />
                                JCB
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={settings.creditCard.types.includes('AmericanExpress')}
                                    onChange={() => handleCreditCardTypeChange('AmericanExpress')}
                                />
                                American Express
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={settings.creditCard.types.includes('DinersClub')}
                                    onChange={() => handleCreditCardTypeChange('DinersClub')}
                                />
                                Diners Club
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={settings.creditCard.types.includes('DiscoverCard')}
                                    onChange={() => handleCreditCardTypeChange('DiscoverCard')}
                                />
                                Discover Card
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={settings.creditCard.types.includes('BCGlobal')}
                                    onChange={() => handleCreditCardTypeChange('BCGlobal')}
                                />
                                BC Global
                            </label>
                        </div>
                    )}
                </div>
                {/* マイナンバー */}
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={settings.myNumber.selected}
                            onChange={(e) => handleSettingChange('myNumber', e.target.checked)}
                        />
                        {t('myNumber')}
                    </label>
                </div>
            </div>
            <div className='personal-info-generate'>
                <button onClick={generatePersonalInfo}>{t('generate')}</button>
                <button onClick={downloadCSV}>{t('downloadCSV')}</button>
            </div>
            <div className="generated-info">
                <h2>{t('generatedInfo')}</h2>
                <table>
                    <thead>
                        <tr>
                            {Object.keys(generatedInfo[0] || {}).map((key) => (
                                <th key={key}>{t(key)}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {generatedInfo.map((info, index) => (
                            <tr key={index}>
                                {Object.values(info).map((value, i) => (
                                    <td key={i}>{value}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default PersonalInfoGenerator;
