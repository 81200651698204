import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import Select from 'react-select';
import '../App.css';
import './css/Date.css';

const eraData = [
    { era: '令和', start: 2019, end: 9999, offset: 2018, maxYear: 9999 - 2018 },
    { era: '平成', start: 1989, end: 2019, offset: 1988, maxYear: 30 },
    { era: '昭和', start: 1926, end: 1989, offset: 1925, maxYear: 63 },
    { era: '大正', start: 1912, end: 1926, offset: 1911, maxYear: 15 },
    { era: '明治', start: 1868, end: 1912, offset: 1867, maxYear: 45 },
    { era: '慶応', start: 1865, end: 1868, offset: 1864, maxYear: 3 },
    { era: '元治', start: 1864, end: 1865, offset: 1863, maxYear: 1 },
    { era: '文久', start: 1861, end: 1864, offset: 1860, maxYear: 3 },
    { era: '万延', start: 1860, end: 1861, offset: 1859, maxYear: 1 },
    { era: '安政', start: 1854, end: 1860, offset: 1853, maxYear: 6 },
    { era: '天保', start: 1830, end: 1844, offset: 1829, maxYear: 14 },
    { era: '文政', start: 1818, end: 1830, offset: 1817, maxYear: 12 },
    { era: '文化', start: 1804, end: 1818, offset: 1803, maxYear: 14 },
    // 必要な元号データをさらに追加してください
];

const DateConverter = () => {
    const { t } = useTranslation();
    const [gregorianYear, setGregorianYear] = useState('');
    const [eraYear, setEraYear] = useState('');
    const [eraName, setEraName] = useState(null);

    const PAGE_NAME = t('dateConverter');
    const PAGE_DESCRIPTION = t('dateConverterDescription');

    const handleGregorianChange = (e) => {
        let year = parseInt(e.target.value, 10);
        if (year > 0) {
            setGregorianYear(e.target.value);
            for (const era of eraData) {
                if (year >= era.start && year < era.end) {
                    setEraName({ value: era.era, label: era.era });
                    setEraYear(year - era.offset);
                    return;
                }
            }
            setEraName(null);
            setEraYear('');
        } else {
            setGregorianYear('');
            setEraName(null);
            setEraYear('');
        }
    };

    const handleEraChange = (e) => {
        let year = parseInt(e.target.value, 10);
        const era = eraData.find((era) => era.era === eraName.value);
        if (era) {
            if (!isNaN(year) && year > 0 && year <= era.maxYear) {
                setEraYear(e.target.value);
                setGregorianYear(era.offset + year);
            } else {
                setEraYear('');
                setGregorianYear('');
            }
        }
    };

    const handleEraNameChange = (selectedOption) => {
        setEraName(selectedOption);
        if (eraYear) {
            let year = parseInt(eraYear, 10);
            if (!isNaN(year)) {
                const era = eraData.find((era) => era.era === selectedOption.value);
                if (era) {
                    setGregorianYear(era.offset + year);
                }
            } else {
                setGregorianYear('');
            }
        }
    };

    const eraOptions = eraData.map((era) => ({
        value: era.era,
        label: era.era
    }));

    return (
        <div>
            <Helmet>
                <title>{PAGE_NAME} - {t('siteName')}</title>
                <meta name="description" content={PAGE_DESCRIPTION.replace(/<br\/>/g, ' ')} />
            </Helmet>
            <h1>{PAGE_NAME}</h1>
            <section className="app-description">
                <h3>{t('description')}</h3>
                <p>
                    <Trans i18nKey={PAGE_DESCRIPTION} components={{ br: <br /> }} />
                </p>
            </section>
            <form>
                <div className='date-form'>
                    <div className='era'>
                        <label>{t('era')}:</label>
                        <Select
                            value={eraName}
                            onChange={handleEraNameChange}
                            options={eraOptions}
                            placeholder={t('selectEra')}
                        />
                        <input
                            type="number"
                            value={eraYear}
                            onChange={handleEraChange}
                            placeholder={t('japaneseYear')}
                            min="1"
                            max={eraName ? eraData.find((era) => era.era === eraName.value).maxYear : ""}
                        />
                    </div>
                    <p>⇄</p>
                    <div>
                        <label>{t('gregorianYear')}:</label>
                        <input
                            type="number"
                            value={gregorianYear}
                            onChange={handleGregorianChange}
                            placeholder={t('gregorianYear')}
                            min="1"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default DateConverter;
