// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generate-count {
    margin-bottom: 10px;
}
.generate-count input[type="number"] {
max-width: 100px;
padding: 10px;
margin-left: 10px;
}
.personal-info-settings {
display: flex;
flex-direction: column;
gap: 10px;
}

.personal-info-settings > div {
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px;
border-radius: 10px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.personal-info-settings .sub-settings {
flex: 0 0 80%;
display: flex;
gap: 10px;
align-items: center;
}

.personal-info-settings select {
padding: 5px;
border-radius: 5px;
border: 1px solid #ccc;
/* background: #f0f0f0; */
}
.sub-settings input[type="checkbox"] {
margin: 0;
}

.personal-info-generate {
display: flex;
align-items: center;
justify-content: center;
gap: 20px;
margin: 20px;
}
.generated-info table {
width: 100%;
border-collapse: collapse;
margin-top: 20px;
}

.generated-info th,
.generated-info td {
border: 1px solid #ccc;
padding: 8px;
text-align: left;
}

.generated-info th {
background-color: #f0f0f0;
}`, "",{"version":3,"sources":["webpack://./src/components/css/PersonalInfo.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;AACA,gBAAgB;AAChB,aAAa;AACb,iBAAiB;AACjB;AACA;AACA,aAAa;AACb,sBAAsB;AACtB,SAAS;AACT;;AAEA;AACA,aAAa;AACb,mBAAmB;AACnB,8BAA8B;AAC9B,aAAa;AACb,mBAAmB;AACnB,wCAAwC;AACxC;;AAEA;AACA,aAAa;AACb,aAAa;AACb,SAAS;AACT,mBAAmB;AACnB;;AAEA;AACA,YAAY;AACZ,kBAAkB;AAClB,sBAAsB;AACtB,yBAAyB;AACzB;AACA;AACA,SAAS;AACT;;AAEA;AACA,aAAa;AACb,mBAAmB;AACnB,uBAAuB;AACvB,SAAS;AACT,YAAY;AACZ;AACA;AACA,WAAW;AACX,yBAAyB;AACzB,gBAAgB;AAChB;;AAEA;;AAEA,sBAAsB;AACtB,YAAY;AACZ,gBAAgB;AAChB;;AAEA;AACA,yBAAyB;AACzB","sourcesContent":[".generate-count {\n    margin-bottom: 10px;\n}\n.generate-count input[type=\"number\"] {\nmax-width: 100px;\npadding: 10px;\nmargin-left: 10px;\n}\n.personal-info-settings {\ndisplay: flex;\nflex-direction: column;\ngap: 10px;\n}\n\n.personal-info-settings > div {\ndisplay: flex;\nalign-items: center;\njustify-content: space-between;\npadding: 10px;\nborder-radius: 10px;\nbox-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.personal-info-settings .sub-settings {\nflex: 0 0 80%;\ndisplay: flex;\ngap: 10px;\nalign-items: center;\n}\n\n.personal-info-settings select {\npadding: 5px;\nborder-radius: 5px;\nborder: 1px solid #ccc;\n/* background: #f0f0f0; */\n}\n.sub-settings input[type=\"checkbox\"] {\nmargin: 0;\n}\n\n.personal-info-generate {\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ngap: 20px;\nmargin: 20px;\n}\n.generated-info table {\nwidth: 100%;\nborder-collapse: collapse;\nmargin-top: 20px;\n}\n\n.generated-info th,\n.generated-info td {\nborder: 1px solid #ccc;\npadding: 8px;\ntext-align: left;\n}\n\n.generated-info th {\nbackground-color: #f0f0f0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
