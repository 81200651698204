import React, { useState, useRef } from 'react';
import { TwitterPicker } from 'react-color';
import { Helmet } from 'react-helmet';
import QRCode from 'qrcode.react';
import '../App.css';
import '../components/css/QRCode.css';
import { useTranslation, Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faStar, faHeart, faThumbsUp, faCoffee } from '@fortawesome/free-solid-svg-icons';

function QRCodeGenerator() {
    const { t } = useTranslation();
    const PAGE_NAME = t('qrCodeGenerator');
    const PAGE_DESCRIPTION = t('qrCodeGeneratorDescription');
    const [text, setText] = useState('');
    const [size, setSize] = useState(128);
    const [color, setColor] = useState('#000000');
    const [format, setFormat] = useState('png');
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [imageType, setImageType] = useState('none');
    const [uploadedImage, setUploadedImage] = useState(null);
    const [generated, setGenerated] = useState(false);
    const canvasRef = useRef(null);

    const iconPalette = [
        { icon: faSmile, name: 'Smile' },
        { icon: faStar, name: 'Star' },
        { icon: faHeart, name: 'Heart' },
        { icon: faThumbsUp, name: 'Thumbs Up' },
        { icon: faCoffee, name: 'Coffee' }
    ];

    const handleSizeChange = (e) => {
        const newSize = Number(e.target.value);
        setSize(newSize);
    };

    const handleDownload = () => {
        const qrCanvas = document.getElementById('qrcode');
        if (!qrCanvas) {
            console.error("QR code canvas element not found.");
            return;
        }

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const padding = 5;
        const qrSize = size;
        const totalSize = qrSize + padding * 2;

        canvas.width = totalSize;
        canvas.height = totalSize;
        context.fillStyle = '#ffffff';
        context.fillRect(0, 0, totalSize, totalSize);
        context.drawImage(qrCanvas, padding, padding, qrSize, qrSize);

        if (selectedIcon || uploadedImage) {
            const img = new Image();
            img.src = selectedIcon ? `data:image/svg+xml;base64,${btoa(new XMLSerializer().serializeToString(selectedIcon))}` : uploadedImage;
            img.onload = () => {
                const imgSize = qrSize * 0.2;
                const imgX = (totalSize - imgSize) / 2;
                const imgY = (totalSize - imgSize) / 2;
                context.drawImage(img, imgX, imgY, imgSize, imgSize);

                const mimeType = format === 'jpg' ? 'image/jpeg' : 'image/png';
                const extension = format === 'jpg' ? 'jpg' : 'png';
                const url = canvas
                    .toDataURL(mimeType)
                    .replace(mimeType, 'image/octet-stream');

                const downloadLink = document.createElement('a');
                downloadLink.href = url;
                downloadLink.download = `qrcode.${extension}`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            };
        } else {
            const mimeType = format === 'jpg' ? 'image/jpeg' : 'image/png';
            const extension = format === 'jpg' ? 'jpg' : 'png';
            const url = canvas
                .toDataURL(mimeType)
                .replace(mimeType, 'image/octet-stream');

            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = `qrcode.${extension}`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    const handleIconSelect = (icon) => {
        setSelectedIcon(icon.icon[4]);
        setUploadedImage(null);
    };

    const insertText = (prefix) => {
        if (text.startsWith('http://') || text.startsWith('https://')) {
            setText(prefix + text.replace(/https?:\/\//, ''));
        } else {
            setText(prefix + text);
        }
    };

    const handleReset = () => {
        setText('');
    };

    const handleGenerate = () => {
        setGenerated(true);
    };

    const handleImageTypeChange = (type) => {
        setImageType(type);
        setSelectedIcon(null);
        setUploadedImage(null);
    };

    const handleImageSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setUploadedImage(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            <Helmet>
                <title>{PAGE_NAME} - {t('siteName')}</title>
                <meta name="description" content={PAGE_DESCRIPTION.replace(/<br\/>/g, ' ')} />
            </Helmet>
            <h1>{PAGE_NAME}</h1>
            <section className="app-description">
                <h3>{t('description')}</h3>
                <p>
                    <Trans i18nKey={PAGE_DESCRIPTION} components={{ br: <br /> }} />
                </p>
            </section>
            <form>
                <input
                    className="input-text"
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder={t('enterText')}
                    required
                />
                <div className="url-buttons">
                    <button type="button" onClick={() => insertText('https://')}>https://</button>
                    <button type="button" onClick={() => insertText('http://')}>http://</button>
                    <button type="button" onClick={handleReset}>{t('reset')}</button>
                </div>
                <div>
                    <label>{t('qrCodeSize')}:</label>
                    <input
                        type="number"
                        value={size}
                        onChange={handleSizeChange}
                        min="64"
                        max="512"
                        step="1"
                    />px
                    <input
                        type="range"
                        value={size}
                        onChange={handleSizeChange}
                        min="64"
                        max="512"
                    />
                </div>
                <TwitterPicker
                    color={color}
                    onChangeComplete={(color) => setColor(color.hex)}
                    colors={['#000000', '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']}
                />
                <div>
                    <label>
                        <input
                            type="radio"
                            value="png"
                            checked={format === 'png'}
                            onChange={() => setFormat('png')}
                        />
                        PNG
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="jpg"
                            checked={format === 'jpg'}
                            onChange={() => setFormat('jpg')}
                        />
                        JPG
                    </label>
                </div>
                <div>
                    <label>{t('selectImageType')}:</label>
                    <select value={imageType} onChange={(e) => handleImageTypeChange(e.target.value)}>
                        <option value="none">{t('none')}</option>
                        {/* <option value="palette">{t('palette')}</option> */}
                        <option value="upload">{t('upload')}</option>
                    </select>
                </div>
                {imageType === 'palette' && (
                    <div>
                        {iconPalette.map((iconObj, index) => (
                            <FontAwesomeIcon
                                key={index}
                                icon={iconObj.icon}
                                size="2x"
                                onClick={() => handleIconSelect(iconObj)}
                                style={{ cursor: 'pointer', margin: '5px', color: selectedIcon === iconObj.icon[4] ? 'blue' : 'black' }}
                            />
                        ))}
                    </div>
                )}
                {imageType === 'upload' && (
                    <div>
                        <label>{t('upload')}:</label>
                        <input type="file" accept="image/*" onChange={handleImageSelect} />
                    </div>
                )}
            </form>
            <div className='generate-button'>
                <button onClick={handleGenerate}>{t('generateQRCode')}</button>
            </div>
            {generated && text && (
                <div className="download">
                    <p>{t('qrCode')}:</p>
                    <div className="qr-code-container">
                        <QRCode
                            id="qrcode"
                            value={text}
                            size={size}
                            fgColor={color}
                            level="H"
                            includeMargin={true}
                        />
                        <canvas ref={canvasRef} id="canvas" style={{ display: 'none' }}></canvas>
                    </div>
                    <button onClick={handleDownload}>{t('download')}</button>
                </div>
            )}
        </div>
    );
}

export default QRCodeGenerator;
