import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../components/css/ThankYou.css';

function ThankYou() {
    const { t } = useTranslation();

    return (
        <div className="thank-you-container">
            <h1>{t('thankYouMessage')}</h1>
            <p>{t('thankYouDescription')}</p>
            <p>{t('thankYouFollowUp')}</p>
            <div className="thank-you-links">
                <button><Link to="/">{t('backToHome')}</Link></button>
            </div>
        </div>
    );
}

export default ThankYou;
