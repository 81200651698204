import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import '../App.css';
import "./css/ColorCode.css"

function ColorCodeConverter() {
    const { t } = useTranslation();
    const PAGE_NAME = t('colorCodeConverter');
    const PAGE_DESCRIPTION = t('colorCodeConverterDescription');
    const [r, setR] = useState(0);
    const [g, setG] = useState(0);
    const [b, setB] = useState(0);
    const [a, setA] = useState(1);
    const [hex, setHex] = useState("#000000");
    const [c, setC] = useState(0);
    const [m, setM] = useState(0);
    const [y, setY] = useState(0);
    const [k, setK] = useState(100);
    const [savedColors, setSavedColors] = useState([]);

    const handleRgbChange = (newR, newG, newB, newA = a) => {
        if (newR < 0 || newR > 255 || newG < 0 || newG > 255 || newB < 0 || newB > 255 || newA < 0 || newA > 1) {
            return;
        }
        setR(newR);
        setG(newG);
        setB(newB);
        setA(newA);
        setHex(rgbToHex(newR, newG, newB));
        const [newC, newM, newY, newK] = rgbToCmyk(newR, newG, newB);
        setC(newC);
        setM(newM);
        setY(newY);
        setK(newK);
    };

    const handleHexChange = (newHex) => {
        if (!/^#([0-9A-Fa-f]{0,6})$/.test(newHex)) {
            return;
        }
        if (!newHex.startsWith('#')) {
            newHex = '#' + newHex;
        }
        setHex(newHex);
        if (newHex.length === 7) {
            const [newR, newG, newB] = hexToRgb(newHex);
            setR(newR);
            setG(newG);
            setB(newB);
            const [newC, newM, newY, newK] = rgbToCmyk(newR, newG, newB);
            setC(newC);
            setM(newM);
            setY(newY);
            setK(newK);
        }
    };

    const handleCmykChange = (newC, newM, newY, newK) => {
        if (newC < 0 || newC > 100 || newM < 0 || newM > 100 || newY < 0 || newY > 100 || newK < 0 || newK > 100) {
            return;
        }
        setC(newC);
        setM(newM);
        setY(newY);
        setK(newK);
        const [newR, newG, newB] = cmykToRgb(newC, newM, newY, newK);
        setR(newR);
        setG(newG);
        setB(newB);
        setHex(rgbToHex(newR, newG, newB));
        setA(1);  // アルファ値は1に固定
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert(t('copied'));
        });
    };

    const resetFields = () => {
        setR(0);
        setG(0);
        setB(0);
        setA(1);
        setHex("#000000");
        setC(0);
        setM(0);
        setY(0);
        setK(100);
    };

    const saveColor = () => {
        const newColor = {
            rgb: `rgb(${r}, ${g}, ${b})`,
            rgba: `rgba(${r}, ${g}, ${b}, ${a})`,
            hex: hex,
            cmyk: `cmyk(${c}%, ${m}%, ${y}%, ${k}%)`,
            display: `rgba(${r}, ${g}, ${b}, ${a})`,
            textColor: getContrastingTextColor(r, g, b, a)
        };
        setSavedColors([...savedColors, newColor]);
    };

    const clearSavedColors = () => {
        setSavedColors([]);
    };

    const getContrastingTextColor = (r, g, b, a) => {
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return (luminance > 0.5 || a < 0.5) ? 'black' : 'white';
    };

    return (
        <div>
            <Helmet>
                <title>{PAGE_NAME} - {t('siteName')}</title>
                <meta name="description" content={PAGE_DESCRIPTION.replace(/<br\/>/g, ' ')} />
            </Helmet>
            <h1>{PAGE_NAME}</h1>
            <section className="app-description">
                <h3>{t('description')}</h3>
                <p>
                    <Trans i18nKey={PAGE_DESCRIPTION} components={{ br: <br /> }} />
                </p>
            </section>
            <div className="color-inputs">
                <div className="color-block">
                    <h2>RGB</h2>
                    <div className="rgb-inputs">
                        <div>
                            <label>R:</label>
                            <input type="number" value={r} onChange={(e) => handleRgbChange(parseInt(e.target.value), g, b)} min="0" max="255" />
                        </div>
                        <div>
                            <label>G:</label>
                            <input type="number" value={g} onChange={(e) => handleRgbChange(r, parseInt(e.target.value), b)} min="0" max="255" />
                        </div>
                        <div>
                            <label>B:</label>
                            <input type="number" value={b} onChange={(e) => handleRgbChange(r, g, parseInt(e.target.value))} min="0" max="255" />
                        </div>
                    </div>
                    <button onClick={() => copyToClipboard(`rgb(${r}, ${g}, ${b})`)}>{t('copyCode')}</button>
                </div>
                <div className="color-block">
                    <h2>RGBA</h2>
                    <div className="rgb-inputs">
                        <div>
                            <label>R:</label>
                            <input type="number" value={r} onChange={(e) => handleRgbChange(parseInt(e.target.value), g, b)} min="0" max="255" />
                        </div>
                        <div>
                            <label>G:</label>
                            <input type="number" value={g} onChange={(e) => handleRgbChange(r, parseInt(e.target.value), b)} min="0" max="255" />
                        </div>
                        <div>
                            <label>B:</label>
                            <input type="number" value={b} onChange={(e) => handleRgbChange(r, g, parseInt(e.target.value))} min="0" max="255" />
                        </div>
                        <div>
                            <label>A:</label>
                            <input type="number" value={a} onChange={(e) => handleRgbChange(r, g, b, parseFloat(e.target.value))} min="0" max="1" step="0.1" />
                        </div>
                    </div>
                    <button onClick={() => copyToClipboard(`rgba(${r}, ${g}, ${b}, ${a})`)}>{t('copyCode')}</button>
                </div>
                <div className="color-block">
                    <h2>HEX</h2>
                    <label>Hex:</label>
                    <input type="text" value={hex} onChange={(e) => handleHexChange(e.target.value.toUpperCase())} maxLength="7" />
                    <button onClick={() => copyToClipboard(hex)}>{t('copyCode')}</button>
                </div>
                <div className="color-block">
                    <h2>CMYK</h2>
                    <div className="cmyk-inputs">
                        <div>
                            <label>C:</label>
                            <input type="number" value={c} onChange={(e) => handleCmykChange(parseInt(e.target.value), m, y, k)} min="0" max="100" />
                        </div>
                        <div>
                            <label>M:</label>
                            <input type="number" value={m} onChange={(e) => handleCmykChange(c, parseInt(e.target.value), y, k)} min="0" max="100" />
                        </div>
                        <div>
                            <label>Y:</label>
                            <input type="number" value={y} onChange={(e) => handleCmykChange(c, m, parseInt(e.target.value), k)} min="0" max="100" />
                        </div>
                        <div>
                            <label>K:</label>
                            <input type="number" value={k} onChange={(e) => handleCmykChange(c, m, y, parseInt(e.target.value))} min="0" max="100" />
                        </div>
                    </div>
                    <button onClick={() => copyToClipboard(`cmyk(${c}%, ${m}%, ${y}%, ${k}%)`)}>{t('copyCode')}</button>
                </div>
            </div>
            <div className="color-results">
                <div className="color-display" style={{ backgroundColor: `rgba(${r}, ${g}, ${b}, ${a})` }}></div>
                <button onClick={saveColor}>{t('saveColor')}</button>
                <button onClick={resetFields}>{t('resetFields')}</button>
            </div>
            <div className="saved-colors">
                {savedColors.length > 0 && (
                    <>
                        <h2>{t('savedColors')}</h2>
                        <div className="saved-colors-list">
                            {savedColors.map((color, index) => (
                                <div key={index} className="saved-color-item" style={{ backgroundColor: color.display, color: color.textColor }}>
                                    <p>{color.rgb}</p>
                                    <p>{color.rgba}</p>
                                    <p>{color.hex}</p>
                                    <p>{color.cmyk}</p>
                                </div>
                            ))}
                        </div>
                        <div className='clear-saved-colors'>
                            <button onClick={clearSavedColors}>{t('clearSavedColors')}</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

// RGB to Hex
function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
}

// Hex to RGB
function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
}

// RGB to CMYK
function rgbToCmyk(r, g, b) {
    let c = 1 - (r / 255);
    let m = 1 - (g / 255);
    let y = 1 - (b / 255);
    let k = Math.min(c, m, y);

    c = (c - k) / (1 - k);
    m = (m - k) / (1 - k);
    y = (y - k) / (1 - k);

    return [c, m, y, k].map(value => Math.round(value * 100));
}

// CMYK to RGB
function cmykToRgb(c, m, y, k) {
    const r = 255 * (1 - c / 100) * (1 - k / 100);
    const g = 255 * (1 - m / 100) * (1 - k / 100);
    const b = 255 * (1 - y / 100) * (1 - k / 100);
    return [Math.round(r), Math.round(g), Math.round(b)];
}

export default ColorCodeConverter;
