import React from 'react';
import { useTranslation } from 'react-i18next';
import '../components/css/TermsOfService.css';

function TermsOfService() {
    const { t } = useTranslation();

    return (
        <div className="terms-container">
            <h1>{t('termsOfServiceTitle')}</h1>
            <p>{t('termsIntroduction')}</p>

            <section>
                <h2>{t('termsApplicabilityTitle')}</h2>
                <p>{t('termsApplicabilityDescription1')}</p>
                <p>{t('termsApplicabilityDescription2')}</p>
                <p>{t('termsApplicabilityDescription3')}</p>
            </section>

            <section>
                <h2>{t('termsProhibitedActivitiesTitle')}</h2>
                <ul>
                    <li>{t('termsProhibitedActivitiesItem1')}</li>
                    <li>{t('termsProhibitedActivitiesItem2')}</li>
                    <li>{t('termsProhibitedActivitiesItem3')}</li>
                    <li>{t('termsProhibitedActivitiesItem4')}</li>
                    <li>{t('termsProhibitedActivitiesItem5')}</li>
                    <li>{t('termsProhibitedActivitiesItem6')}</li>
                </ul>
            </section>

            <section>
                <h2>{t('termsRestrictionOfUseTitle')}</h2>
                <ul>
                    <li>{t('termsRestrictionOfUseItem1')}</li>
                    <li>{t('termsRestrictionOfUseItem2')}</li>
                    <li>{t('termsRestrictionOfUseItem3')}</li>
                </ul>
            </section>

            <section>
                <h2>{t('termsSuspensionOfServiceTitle')}</h2>
                <ul>
                    <li>{t('termsSuspensionOfServiceItem1')}</li>
                    <li>{t('termsSuspensionOfServiceItem2')}</li>
                    <li>{t('termsSuspensionOfServiceItem3')}</li>
                    <li>{t('termsSuspensionOfServiceItem4')}</li>
                </ul>
            </section>

            <section>
                <h2>{t('termsDisclaimerTitle')}</h2>
                <ul>
                    <li>{t('termsDisclaimerItem1')}</li>
                    <li>{t('termsDisclaimerItem2')}</li>
                    <li>{t('termsDisclaimerItem3')}</li>
                </ul>
            </section>

            <section>
                <h2>{t('termsChangesToServiceTitle')}</h2>
                <p>{t('termsChangesToServiceDescription')}</p>
            </section>

            <section>
                <h2>{t('termsChangesToTermsTitle')}</h2>
                <p>{t('termsChangesToTermsDescription')}</p>
            </section>

            <section>
                <h2>{t('termsPersonalInformationTitle')}</h2>
                <p>{t('termsPersonalInformationDescription')}</p>
            </section>

            <section>
                <h2>{t('termsGoverningLawTitle')}</h2>
                <p>{t('termsGoverningLawDescription')}</p>
            </section>
        </div>
    );
}

export default TermsOfService;
