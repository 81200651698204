import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import '../App.css';

function PasswordGenerator() {
    const { t } = useTranslation();
    const PAGE_NAME = t('passwordGenerator');
    const PAGE_DESCRIPTION = t('passwordGeneratorDescription');
    const [length, setLength] = useState(8);
    const [count, setCount] = useState(1);
    const [useNumbers, setUseNumbers] = useState(true);
    const [useLowercase, setUseLowercase] = useState(true);
    const [useUppercase, setUseUppercase] = useState(true);
    const [useSymbols1, setUseSymbols1] = useState(false);
    const [useSymbols2, setUseSymbols2] = useState(false);
    const [useSymbols3, setUseSymbols3] = useState(false);
    const [passwords, setPasswords] = useState([]);

    const generatePassword = (length, charset) => {
        let password = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }
        return password;
    };

    const handleGenerate = () => {
        let charset = '';
        if (useNumbers) charset += '0123456789';
        if (useLowercase) charset += 'abcdefghijklmnopqrstuvwxyz';
        if (useUppercase) charset += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        if (useSymbols1) charset += '!@#';
        if (useSymbols2) charset += '$%^';
        if (useSymbols3) charset += '&*()';
        
        if (charset === '') {
            alert(t('selectCharsetAlert'));
            return;
        }

        const newPasswords = [];
        for (let i = 0; i < count; i++) {
            newPasswords.push(generatePassword(length, charset));
        }
        setPasswords(newPasswords);
    };

    const handleCopy = () => {
        const text = passwords.join('\n');
        navigator.clipboard.writeText(text).then(() => {
            alert(t('passwordsCopied'));
        });
    };

    const handleDownloadCSV = () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        passwords.forEach(password => {
            csvContent += password + "\n";
        });
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "passwords.csv");
        document.body.appendChild(link);
        link.click();
    };

    return (
        <div>
            <Helmet>
                <title>{PAGE_NAME} - {t('siteName')}</title>
                <meta name="description" content={PAGE_DESCRIPTION.replace(/<br\/>/g, ' ')} />
            </Helmet>
            <h1>{PAGE_NAME}</h1>
            <section className="app-description">
                <h3>{t('description')}</h3>
                <p>
                    <Trans i18nKey={PAGE_DESCRIPTION} components={{ br: <br /> }} />
                </p>
            </section>
            <form onSubmit={(e) => e.preventDefault()}>
                <label>
                    {t('passwordLength')}:
                    <input
                        type="number"
                        min="4"
                        max="64"
                        value={length}
                        onChange={(e) => setLength(Number(e.target.value))}
                    />
                </label>
                <label>
                    {t('generateCount')}:
                    <input
                        type="number"
                        min="1"
                        max="500"
                        value={count}
                        onChange={(e) => setCount(Number(e.target.value))}
                    />
                </label>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={useNumbers}
                            onChange={(e) => setUseNumbers(e.target.checked)}
                        />
                        {t('numbers')}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={useLowercase}
                            onChange={(e) => setUseLowercase(e.target.checked)}
                        />
                        {t('lowercase')}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={useUppercase}
                            onChange={(e) => setUseUppercase(e.target.checked)}
                        />
                        {t('uppercase')}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={useSymbols1}
                            onChange={(e) => setUseSymbols1(e.target.checked)}
                        />
                        {t('symbols')} (!@#)
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={useSymbols2}
                            onChange={(e) => setUseSymbols2(e.target.checked)}
                        />
                        {t('symbols')} ($%^)
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={useSymbols3}
                            onChange={(e) => setUseSymbols3(e.target.checked)}
                        />
                        {t('symbols')} (&*())
                    </label>
                </div>
                <button onClick={handleGenerate}>{t('generatePassword')}</button>
            </form>
            <div>
                {passwords.map((password, index) => (
                    <ul key={index}>
                        <li>{password}</li>
                    </ul>
                ))}
            </div>
            <div className='download'>
                <button onClick={handleCopy}>{t('copyPasswords')}</button>
                <button onClick={handleDownloadCSV}>{t('downloadCSV')}</button>
            </div>
        </div>
    );
}

export default PasswordGenerator;
