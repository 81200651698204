// frontend/src/components/URLShortener.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import axios from 'axios';
import '../App.css';

function URLShortener() {
    const { t } = useTranslation();
    const PAGE_NAME = t('urlShortener');
    const PAGE_DESCRIPTION = t('urlShortenerDescription');
    const [url, setUrl] = useState('');
    const [shortUrl, setShortUrl] = useState('');
    const [error, setError] = useState('');
    const [copySuccess, setCopySuccess] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/shorten`, { original_url: url });
            setShortUrl(response.data.short_url);
            setError('');
        } catch (error) {
            console.error('Failed to fetch', error);
            setError('URLの短縮に失敗しました。再度お試しください。');
        }
    };

    const insertText = (prefix) => {
        if (url.startsWith('http://') || url.startsWith('https://')) {
            setUrl(prefix + url.replace(/https?:\/\//, ''));
        } else {
            setUrl(prefix + url);
        }
    };

    const handleReset = () => {
        setUrl('');
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shortUrl).then(() => {
            setCopySuccess('コピーしました！');
            setTimeout(() => setCopySuccess(''), 2000); // 2秒後にメッセージを消す
        }, (err) => {
            setCopySuccess('コピーに失敗しました。');
        });
    };

    return (
        <div>
            <Helmet>
                <title>{PAGE_NAME} - {t('siteName')}</title>
                <meta name="description" content={PAGE_DESCRIPTION.replace(/<br\/>/g, ' ')} />
                <meta property="og:title" content="URL短縮 - GenieApp"/>
                <meta property="og:description" content={PAGE_DESCRIPTION.replace(/<br\/>/g, ' ')} />
                <meta property="og:url" content="https://genieapp.space/url-shorten"/>
                <meta property="og:image" content="https://genieapp.space/logo.png"/>
                <meta name="twitter:title" content="URL短縮 - GenieApp"/>
                <meta name="twitter:description" content={PAGE_DESCRIPTION.replace(/<br\/>/g, ' ')} />
            </Helmet>
            <h1>{PAGE_NAME}</h1>
            <strong><p>{t('preparation')}</p></strong>
            <section className="app-description">
                <h3>{t('description')}</h3>
                <p>
                    <Trans i18nKey={PAGE_DESCRIPTION} components={{ br: <br /> }} />
                </p>
            </section>
            <form onSubmit={handleSubmit}>
                <input className="input-text"
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="https://example.com"
                    required
                />
                <div className="url-buttons">
                    <button type="button" onClick={() => insertText('https://')}>https://</button>
                    <button type="button" onClick={() => insertText('http://')}>http://</button>
                    <button type="button" onClick={handleReset}>{t('reset')}</button>
                </div>
                <button type="submit">{t('shorten')}</button>
            </form>
            {error && <p style={{color: 'red'}}>{error}</p>}
            {shortUrl && (
                <div className='download'>
                    <p>短縮URL: <a href={shortUrl} target="_blank" rel="noopener noreferrer">{shortUrl}</a></p>
                    <button onClick={copyToClipboard}>URLをコピー</button>
                    {copySuccess && <p style={{color: 'green'}}>{copySuccess}</p>}
                </div>
            )}
        </div>
    );
}

export default URLShortener;
