import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import axios from 'axios';

const ExcelToCsvConverter = () => {
    const { t } = useTranslation();
    const PAGE_NAME = t('excelToCsvConverter');
    const PAGE_DESCRIPTION = t('excelToCsvConverterDescription');
    const META_DESCRIPTION = t('excelToCsvConverterMetaDescription')
    const [file, setFile] = useState(null);
    const [csvFiles, setCsvFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [encoding, setEncoding] = useState('Shift JIS');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/convert-excel-to-csv/?encoding=${encoding}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data && response.data.csv_files) {
                setCsvFiles(response.data.csv_files);
            } else {
                console.error('No CSV files returned');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleSelectFile = (fileName) => {
        setSelectedFiles((prevSelectedFiles) => {
            if (prevSelectedFiles.includes(fileName)) {
                return prevSelectedFiles.filter((file) => file !== fileName);
            } else {
                return [...prevSelectedFiles, fileName];
            }
        });
    };

    const handleBulkDownload = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/download-zip/`, selectedFiles, {
                headers: {
                    'Content-Type': 'application/json',
                },
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'csv_files.zip');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error downloading zip file:', error);
        }
    };

    return (
        <div>
        <Helmet>
            <title>{PAGE_NAME} - {t('siteName')}</title>
            <meta name="description" content={META_DESCRIPTION.replace(/<br\/>/g, ' ')} />
        </Helmet>
        <h1>{PAGE_NAME}</h1>
        <section className="app-description">
            <h3>{t('description')}</h3>
            <p>
                <Trans i18nKey={PAGE_DESCRIPTION} components={{ br: <br /> }} />
            </p>
        </section>
            <input type="file" onChange={handleFileChange} accept=".xlsx,.xls,.xlsm" />
            <select value={encoding} onChange={(e) => setEncoding(e.target.value)}>
                <option value="shift_jis">Shift JIS</option>
                <option value="utf-8">UTF-8</option>
            </select>
            <button onClick={handleUpload}>{t('upload')}</button>
            {csvFiles.length > 0 && (
                <div>
                    <h2>{t('convertedCsvFiles')}</h2>
                    <ul>
                        {csvFiles.map((csvFile, index) => (
                            <li key={index}>
                                <input
                                    type="checkbox"
                                    checked={selectedFiles.includes(csvFile)}
                                    onChange={() => handleSelectFile(csvFile)}
                                />
                                <a href={`${process.env.REACT_APP_API_BASE_URL}download-csv/${csvFile}`} download>{csvFile}</a>
                            </li>
                        ))}
                    </ul>
                    <button onClick={handleBulkDownload}>{t('bulkDownload')}</button>
                </div>
            )}
        </div>
    );
};

export default ExcelToCsvConverter;