import React, { useState, useRef } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Helmet } from 'react-helmet';
import '../App.css';
import { useTranslation, Trans } from 'react-i18next';

function WiFiQRCodeGenerator() {
    const { t } = useTranslation();
    const PAGE_NAME = t('wifiQrCodeGenerator');
    const PAGE_DESCRIPTION = t('wifiQrCodeGeneratorDescription');
    const [ssid, setSsid] = useState('');
    const [password, setPassword] = useState('');
    const [qrValue, setQrValue] = useState('');
    const [error, setError] = useState('');
    const [showDetails, setShowDetails] = useState(false);
    const [size, setSize] = useState(256);
    const [fontSize, setFontSize] = useState(18);
    const canvasRef = useRef(null);

    const generateQRCode = (e) => {
        e.preventDefault();

        if (!ssid) {
            setError(t('enterSsid'));
            return;
        }

        const wifiConfig = `WIFI:T:WPA;S:${ssid};P:${password};;`;
        setQrValue(wifiConfig);
        setError('');
    };

    const drawText = (context, text, x, y, maxWidth, lineHeight) => {
        const words = text.split(' ');
        let line = '';
        let totalHeight = 0;

        for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = context.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
                totalHeight += lineHeight;
            } else {
                line = testLine;
            }
        }
        context.fillText(line, x, y);
        totalHeight += lineHeight;
        return totalHeight;
    };

    const downloadQRCode = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const qrCanvas = document.getElementById('qrCode');

        const qrSize = size;
        const padding = 20;
        const textPadding = 10;
        const maxWidth = Math.max(qrSize + 2 * padding, context.measureText(`SSID: ${ssid}`).width, context.measureText(`PASS: ${password}`).width) + 20;
        let totalHeight = qrSize + 2 * padding;

        if (showDetails) {
            context.font = `bold ${fontSize}px Arial`;
            const ssidHeight = drawText(context, `SSID: ${ssid}`, 0, 0, maxWidth - 40, fontSize + 2);
            const passHeight = drawText(context, `PASS: ${password}`, 0, 0, maxWidth - 40, fontSize + 2);
            totalHeight += ssidHeight + passHeight + textPadding;
        }

        canvas.width = maxWidth;
        canvas.height = totalHeight;

        // 背景を白色で塗りつぶす
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvas.width, canvas.height);

        // QRコードをキャンバスの中央に描画
        const qrOffsetX = (canvas.width - qrSize) / 2;
        const qrOffsetY = padding;
        context.drawImage(qrCanvas, qrOffsetX, qrOffsetY, qrSize, qrSize);

        // SSIDとパスワードを描画
        if (showDetails) {
            context.font = `${fontSize}px Arial`;
            context.fillStyle = 'black';
            context.textAlign = 'center';

            const textY = qrSize + qrOffsetY + textPadding + fontSize;
            const ssidText = `SSID: ${ssid}`;
            const passText = `PASS: ${password}`;

            const ssidTextHeight = drawText(context, ssidText, canvas.width / 2, textY, maxWidth - 40, fontSize + 2);
            drawText(context, passText, canvas.width / 2, textY + ssidTextHeight + textPadding, maxWidth - 40, fontSize + 2);
        }

        // ダウンロード
        const pngUrl = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream');
        let downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `${ssid}_qr.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div>
            <Helmet>
                <title>{PAGE_NAME} - {t('siteName')}</title>
                <meta name="description" content={PAGE_DESCRIPTION.replace(/<br\/>/g, ' ')} />
            </Helmet>
            <h1>{PAGE_NAME}</h1>
            <section className="app-description">
                <h3>{t('description')}</h3>
                <p>
                    <Trans i18nKey={PAGE_DESCRIPTION} components={{ br: <br /> }} />
                </p>
            </section>
            <form onSubmit={generateQRCode}>
                <input
                    className="input-text"
                    type="text"
                    value={ssid}
                    onChange={(e) => setSsid(e.target.value)}
                    placeholder="SSID"
                    required
                />
                <input
                    className="input-text"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="PASS"
                />
                <label>
                    <input
                        type="checkbox"
                        checked={showDetails}
                        onChange={(e) => setShowDetails(e.target.checked)}
                    />
                    {t('showSsidPassword')}
                </label>
                <div>
                    <label>{t('qrCodeSize')}:</label>
                    <input
                        type="number"
                        value={size}
                        onChange={(e) => setSize(parseInt(e.target.value))}
                        min="100"
                        max="1000"
                        step="1"
                    />px
                    <input
                        type="range"
                        value={size}
                        onChange={(e) => setSize(parseInt(e.target.value))}
                        min="100"
                        max="1000"
                    />
                </div>
                <div>
                    <label>{t('fontSize')}:</label>
                    <input
                        type="number"
                        value={fontSize}
                        onChange={(e) => setFontSize(parseInt(e.target.value))}
                        min="10"
                        max="50"
                        step="1"
                    />px
                    <input
                        type="range"
                        value={fontSize}
                        onChange={(e) => setFontSize(parseInt(e.target.value))}
                        min="10"
                        max="50"
                    />
                </div>
                <button type="submit">{t('generateQRCode')}</button>
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {qrValue && (
                <div className='download'>
                    <p>{t('wifiQrCode')}:</p>
                    <div className="qr-code-container">
                        <QRCode
                            id="qrCode"
                            value={qrValue}
                            size={size}
                            logoImage="/img/wifi-icon.png"
                            logoWidth={size * 0.15}
                            logoHeight={size * 0.15}
                            logoOpacity={1}
                            removeQrCodeBehindLogo={true}
                        />
                        <canvas ref={canvasRef} id="canvas" style={{ display: 'none' }}></canvas>
                    </div>
                    {showDetails && (
                        <div>
                            <p style={{ fontSize: `${fontSize}px` }}>SSID: {ssid}</p>
                            <p style={{ fontSize: `${fontSize}px` }}>PASS: {password}</p>
                        </div>
                    )}
                    <button id="wifi-qrcode-download" onClick={downloadQRCode}>{t('download')}</button>
                </div>
            )}
        </div>
    );
}

export default WiFiQRCodeGenerator;
