import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import '../components/css/FaviconGenerator.css';
import { useTranslation, Trans } from 'react-i18next';

const FaviconGenerator = () => {
    const { t } = useTranslation();
    const PAGE_NAME = t('faviconGenerator');
    const PAGE_DESCRIPTION = t('faviconGeneratorDescription');
    const [file, setFile] = useState(null);
    const [sizes, setSizes] = useState([]);
    const [downloadUrl, setDownloadUrl] = useState('');
    const [faviconPreview, setFaviconPreview] = useState(null); // 生成されたファビコンのプレビュー用ステート

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

        if (selectedFile && allowedTypes.includes(selectedFile.type)) {
            setFile(selectedFile);
            setSizes([]);
        } else {
            alert(t('selectValidFile'));
            setFile(null);
        }
    };

    const handleSizeChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (e.target.checked) {
            setSizes([...sizes, value]);
        } else {
            setSizes(sizes.filter(size => size !== value));
        }
    };

    const handleUpload = async () => {
        if (!file) {
            alert(t('pleaseSelectFile'));
            return;
        }

        if (sizes.length === 0) {
            alert(t('pleaseSelectSize'));
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('sizes', sizes);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/generate-favicon/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            setDownloadUrl(url);

            // 生成されたファビコンのプレビューを表示
            setFaviconPreview(url);
        } catch (error) {
            console.error('Error generating favicon:', error);
        }
    };

    return (
        <div>
            <Helmet>
                <title>{PAGE_NAME} - {t('siteName')}</title>
                <meta name="description" content={PAGE_DESCRIPTION.replace(/<br\/>/g, ' ')} />
            </Helmet>
            <h1>{PAGE_NAME}</h1>
            <section className="app-description">
                <h3>{t('description')}</h3>
                <p>
                    <Trans i18nKey={PAGE_DESCRIPTION} components={{ br: <br /> }} />
                    <Trans i18nKey={t('sizeSelectionInstruction')} components={{ br: <br /> }} />
                </p>
            </section>
            <h3>{t('selectFile')}</h3>
            <input type="file" onChange={handleFileChange} accept=".jpg,.jpeg,.png,.gif" />
            <div>
                <h3>{t('selectSize')}</h3>
                <div className='select-size'>
                    {[
                        { size: '16x16', description: t('16x16') },
                        { size: '24x24', description: t('24x24') },
                        { size: '32x32', description: t('32x32') },
                        { size: '48x48', description: t('48x48') },
                        { size: '64x64', description: t('64x64') },
                        { size: '96x96', description: t('96x96') },
                        { size: '180x180', description: t('180x180') },
                        { size: '192x192', description: t('192x192') },
                        { size: '256x256', description: t('256x256') },
                    ].map(({ size, description }) => (
                        <div key={size}>
                            <label className='size'>
                                <input type="radio" name="size" value={size} onChange={handleSizeChange} />{size} - {description}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
            <button onClick={handleUpload}>{t('generateFavicon')}</button>
            {faviconPreview && (
                <div>
                    <h3>{t('preview')}</h3>
                    <img src={faviconPreview} alt="Favicon Preview" width={sizes} height={sizes} />
                </div>
            )}
            {downloadUrl && (
                <div>
                    <button onClick={() => window.location.href = downloadUrl}>
                        {t('downloadFavicon')}
                    </button>
                </div>
            )}
        </div>
    );
};

export default FaviconGenerator;
