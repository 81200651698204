import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import ja from './ja.json';
import enTerms from './terms/en_terms.json';
import jaTerms from './terms/ja_terms.json';

// マージ処理
const mergedEn = { ...en, ...enTerms };
const mergedJa = { ...ja, ...jaTerms };

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
resources: {
    en: {
    translation: mergedEn,
    },
    ja: {
    translation: mergedJa,
    },
},
fallbackLng: 'en',
interpolation: {
    escapeValue: false,
},
});

export default i18n;
