// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: #1e90ff;
    color: #ffffff;
    padding: 30px 30px 30px 250px;
    text-align: center;
}

.footer-links a, .social-media a {
color: #ffffff;
margin: 0 10px;
text-decoration: none;
}

.footer-links a:hover, .social-media a:hover {
text-decoration: underline;
}
@media (max-width: 768px) {
    .footer {
        padding: 30px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/css/Footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;AACA,cAAc;AACd,cAAc;AACd,qBAAqB;AACrB;;AAEA;AACA,0BAA0B;AAC1B;AACA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".footer {\n    background-color: #1e90ff;\n    color: #ffffff;\n    padding: 30px 30px 30px 250px;\n    text-align: center;\n}\n\n.footer-links a, .social-media a {\ncolor: #ffffff;\nmargin: 0 10px;\ntext-decoration: none;\n}\n\n.footer-links a:hover, .social-media a:hover {\ntext-decoration: underline;\n}\n@media (max-width: 768px) {\n    .footer {\n        padding: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
