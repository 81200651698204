import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const ContactList = () => {
    const { t } = useTranslation();
    const [contacts, setContacts] = useState([]);
    const [password, setPassword] = useState("");
    const [authenticated, setAuthenticated] = useState(false);
    const correctPassword = process.env.REACT_APP_CONTACT_LIST_PASSWORD;

    useEffect(() => {
        if (authenticated) {
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/contacts`)
                .then(response => {
                    setContacts(response.data);
                })
                .catch(error => {
                    console.error(t('fetchError'), error);
                });
        }
    }, [authenticated, t]);

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === correctPassword) {
            setAuthenticated(true);
        } else {
            alert(t('wrongPassword'));
        }
    };

    if (!authenticated) {
        return (
            <div>
                <h2>{t('enterPassword')}</h2>
                <form onSubmit={handlePasswordSubmit}>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={t('password')}
                    />
                    <button type="submit">{t('submit')}</button>
                </form>
            </div>
        );
    }

    return (
        <div>
            <h2>{t('contactListTitle')}</h2>
            <table>
                <thead>
                <tr>
                    <th>{t('name')}</th>
                    <th>{t('email')}</th>
                    <th>{t('message')}</th>
                    <th>{t('date')}</th>
                </tr>
                </thead>
                <tbody>
                {contacts.map(contact => (
                    <tr key={contact.id}>
                        <td>{contact.name}</td>
                        <td>{contact.email}</td>
                        <td>{contact.message}</td>
                        <td>{new Date(contact.created_at).toLocaleString()}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default ContactList;
