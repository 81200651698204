import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SITE_NAME } from '../App';
import './css/Header.css';
import { useTranslation } from 'react-i18next';

const Header = ({ showSidebar }) => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
    };
    return (
        <header className="header">
            <div className="mobile-header">
                <Link to="/" className="logo-link">{SITE_NAME}</Link>
                <div className="language-dropdown-header">
                    <select
                        value={language}
                        onChange={(e) => changeLanguage(e.target.value)}
                        name='language'
                    >
                        <option value="en">English</option>
                        <option value="ja">日本語</option>
                    </select>
                </div>
            </div>
        </header>
    );
};

export default Header;
