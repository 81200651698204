// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.url-buttons button {
    background-color: #cccccc;
    padding: 5px 10px;
    font-size: 14px;
    margin-right: 5px;
    border: none;
    cursor: pointer;
}

.url-buttons {
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/css/QRCode.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".url-buttons button {\n    background-color: #cccccc;\n    padding: 5px 10px;\n    font-size: 14px;\n    margin-right: 5px;\n    border: none;\n    cursor: pointer;\n}\n\n.url-buttons {\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
