import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SITE_NAME } from '../App';
import { useTranslation, Trans } from 'react-i18next';

function Home() {
    const { t } = useTranslation();

    return (
        <div className="App">
            <Helmet>
                <title>{SITE_NAME}</title>
                <meta name="description" content={t('siteDescription')} />
                <meta name="keywords" content={t('keywords')} />
                <meta name="author" content="株式会社Optiru" />
                <meta property="og:site_name" content={SITE_NAME} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={SITE_NAME} />
                <meta property="og:description" content={t('siteDescription')} />
                <meta property="og:url" content="https://genieapp.space" />
                <meta property="og:image" content="https://genieapp.space/og-image.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={SITE_NAME} />
                <meta name="twitter:description" content={t('siteDescription')} />
                <meta name="twitter:image" content="https://genieapp.space/twitter-image.jpg" />
            </Helmet>
            <h1>{t('welcomeMessage')}</h1>
            <p>{t('siteDescription')}</p>
            <div className="tool-list home-tool-list">
                <Link to="/qr-generator" className="tool-item">
                    <div className="tool-image">{t('qrCodeGenerator')}</div>
                    <h2>{t('qrCodeGenerator')}</h2>
                    <p className="home-description"><Trans i18nKey="qrCodeGeneratorDescription" components={{ br: <br /> }} /></p>
                </Link>
                <Link to="/password-generator" className="tool-item">
                    <div className="tool-image">{t('passwordGenerator')}</div>
                    <h2>{t('passwordGenerator')}</h2>
                    <p className="home-description"><Trans i18nKey="passwordGeneratorDescription" components={{ br: <br /> }} /></p>
                </Link>
                <Link to="/wifi-qr-generator" className="tool-item">
                    <div className="tool-image">{t('wifiQrCodeGenerator')}</div>
                    <h2>{t('wifiQrCodeGenerator')}</h2>
                    <p className="home-description"><Trans i18nKey="wifiQrCodeGeneratorDescription" components={{ br: <br /> }} /></p>
                </Link>
                <Link to="/color-code-converter" className="tool-item">
                    <div className="tool-image">{t('colorCodeConverter')}</div>
                    <h2>{t('colorCodeConverter')}</h2>
                    <p className="home-description"><Trans i18nKey="colorCodeConverterDescription" components={{ br: <br /> }} /></p>
                </Link>
                <Link to="/personal-info-generator" className="tool-item">
                    <div className="tool-image">{t('personalInfoGenerator')}</div>
                    <h2>{t('personalInfoGenerator')}</h2>
                    <p className="home-description"><Trans i18nKey="personalInfoGeneratorDescription" components={{ br: <br /> }} /></p>
                </Link>
                <Link to="/date-converter" className="tool-item">
                    <div className="tool-image">{t('dateConverter')}</div>
                    <h2>{t('dateConverter')}</h2>
                    <p className="home-description"><Trans i18nKey="dateConverterDescription" components={{ br: <br /> }} /></p>
                </Link>
                <Link to="/excel-to-csv" className="tool-item">
                    <div className="tool-image">{t('excelToCsvConverter')}</div>
                    <h2>{t('excelToCsvConverter')}</h2>
                    <p className="home-description"><Trans i18nKey="excelToCsvConverterDescription" components={{ br: <br /> }} /></p>
                </Link>
                <Link to="/favicon-generator" className="tool-item">
                    <div className="tool-image">{t('faviconGenerator')}</div>
                    <h2>{t('faviconGenerator')}</h2>
                    <p className="home-description"><Trans i18nKey="faviconGeneratorDescription" components={{ br: <br /> }} /></p>
                </Link>
                <Link to="/url-shorten" className="tool-item">
                    <div className="tool-image">{t('urlShortener')}</div>
                    <h2>{t('urlShortener')}</h2>
                    <p className="home-description"><Trans i18nKey="urlShortenerDescription" components={{ br: <br /> }} /></p>
                </Link>
            </div>
        </div>
    );
}

export default Home;
