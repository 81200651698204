import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { SITE_NAME } from '../App';
import './css/Sidebar.css';
import { useTranslation } from 'react-i18next';

function Sidebar({ sidebar, setSidebar }) {
    const showSidebar = () => setSidebar(!sidebar);
    const { i18n, t } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const location = useLocation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
    };

    return (
        <>
            <div className="nav-icon" onClick={showSidebar}>
                {sidebar ? <AiOutlineClose className="close-icon" /> : <AiOutlineMenu className="menu-icon" />}
            </div>
            <nav className={`sidebar ${sidebar ? 'show' : ''}`}>
                <div className="sidebar-header">
                    <AiOutlineClose className="close-icon" onClick={showSidebar} />
                    <Link to="/" onClick={showSidebar} className="logo-link">{SITE_NAME}</Link>
                    <div className="language-dropdown">
                        <select
                            value={language}
                            onChange={(e) => changeLanguage(e.target.value)}
                        >
                            <option value="en">English</option>
                            <option value="ja">日本語</option>
                        </select>
                    </div>
                </div>
                <Link to="/qr-generator" onClick={showSidebar} className={location.pathname === '/qr-generator' ? 'active' : ''}>{t('qrCodeGenerator')}</Link>
                <Link to="/wifi-qr-generator" onClick={showSidebar} className={location.pathname === '/wifi-qr-generator' ? 'active' : ''}>{t('wifiQrCodeGenerator')}</Link>
                <Link to="/password-generator" onClick={showSidebar} className={location.pathname === '/password-generator' ? 'active' : ''}>{t('passwordGenerator')}</Link>
                <Link to="/color-code-converter" onClick={showSidebar} className={location.pathname === '/color-code-converter' ? 'active' : ''}>{t('colorCodeConverter')}</Link>
                <Link to="/personal-info-generator" onClick={showSidebar} className={location.pathname === '/personal-info-generator' ? 'active' : ''}>{t('personalInfoGenerator')}</Link>
                <Link to="/date-converter" onClick={showSidebar} className={location.pathname === '/date-converter' ? 'active' : ''}>{t('dateConverter')}</Link>
                <Link to="/excel-to-csv" onClick={showSidebar} className={location.pathname === '/excel-to-csv' ? 'active' : ''}>{t('excelToCsvConverter')}</Link>
                <Link to="/favicon-generator" onClick={showSidebar} className={location.pathname === '/favicon-generator' ? 'active' : ''}>{t('faviconGenerator')}</Link>
                <Link to="/patient-info-extractor" onClick={showSidebar} className={location.pathname === '/patient-info-extractor' ? 'active' : ''}>{t('patientInfoExtractor')}</Link>
                <Link to="/url-shorten" onClick={showSidebar} className={location.pathname === '/url-shorten' ? 'active' : ''}>{t('urlShortener')}</Link>
            </nav>
        </>
    );
}

export default Sidebar;
